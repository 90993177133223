<template>
  <div>
    <fieldsRows
      :templateContent="templateContent"
      @field-value-changed="updateFieldValue(...arguments)"
      :valueLine="localStoredValue"
      :isValid="isValid"
      v-on="$listeners"
      @type-changed="changeTargetType"
    ></fieldsRows>
    <div v-if="checkUplink" class="mb-3">
      <v-card class="mb-6 mt-3" elevation="0" outlined>
        <containerTitle title="Evaluation Uplink"> </containerTitle>
        <v-card-text>
          <lineFieldsArray
            :fieldAttrRow="{}"
            :fields="fields"
            :templateContent="result"
            :value="upLinkPairArray"
            @line-field-input="checkInput(...arguments, 'uplink')"
            :headerTitle="true"
            :addable="true"
            addButtTitle="Add Eval"
            :linePrefix="stepType + '_' + stepID + '_uplink_eval'"
            :isValid="isValid"
            v-on="$listeners"
          ></lineFieldsArray>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="checkDownlink">
      <v-card class="mb-6 mt-3" elevation="0" outlined>
        <containerTitle title="Evaluation Downlink"> </containerTitle>
        <v-card-text>
          <lineFieldsArray
            :fieldAttrRow="{}"
            :fields="fields"
            :templateContent="result"
            :value="downLinkPairArray"
            @line-field-input="checkInput(...arguments, 'downlink')"
            :headerTitle="true"
            :addable="true"
            addButtTitle="Add Eval"
            :linePrefix="stepType + '_' + stepID + '_downlink_eval'"
            :isValid="isValid"
            v-on="$listeners"
          ></lineFieldsArray>
        </v-card-text>
      </v-card>
    </div>
    <explorerOnError
      v-model="onErrorVal"
      :result="{
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        enableAdd: false,
        contentID: this.stepType + '_' + this.stepID,
      }"
    ></explorerOnError>
    <explorerCollectStatistics v-model="statistic" :result="result">
    </explorerCollectStatistics>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import teststepDefault from "@/cfg/teststep-default.json";

import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import containerTitle from "@/commonComponents/containerTitle.vue";
import lineButtons from "@/cfg/lineButtons.json";
import options from "@/cfg/options.json";
import explorerOnError from "@/components/dynamic/explorerOnError.vue";
import explorerCollectStatistics from "@/components/legacy/explorerCollectStatistics.vue";

export default {
  components: {
    fieldsRows,
    lineFieldsArray,
    explorerOnError,
    containerTitle,
    explorerCollectStatistics,
  },
  data() {
    return {
      options: options,
    };
  },
  props: ["result", "stepStoredValue", "stepType", "stepID", "isValid"],
  computed: {
    statistic: {
      get() {
        return this.stepStoredValue.statistic;
      },
      set(newVal) {
        console.log(newVal);
        this.$set(this.stepStoredValue, "statistic", newVal);
        this.$emit("input", this.stepStoredValue);
      },
    },
    checkUplink() {
      let value = false;
      if (
        typeof this.localStoredValue.parameters != "undefined" &&
        typeof this.localStoredValue.parameters.uplink != "undefined" &&
        this.localStoredValue.parameters.uplink == 1
      ) {
        value = true;
      }
      return value;
    },
    checkDownlink() {
      let value = false;
      if (
        typeof this.localStoredValue.parameters != "undefined" &&
        typeof this.localStoredValue.parameters.downlink != "undefined" &&
        this.localStoredValue.parameters.downlink == 1
      ) {
        value = true;
      }
      return value;
    },
    fields() {
      let fields = {
        type: {
          type: "combobox",
          associatedOption: this.result.additional.explorerEvalType,
          name: "Type",
          fieldAttrInput: { class: "required" },
        },
        operator: {
          type: "select",
          associatedOption: this.result.additional.explorerEvalOperator,
          name: "Operator",
          fieldAttrInput: { class: "required" },
        },
        content: {
          inputFieldFormatter: "udpEvalValue",
          AvgMaxNumber: 100,
        },
        else: {
          input: false,
          type: "string",
          string: "else",
          colAttrs: {
            style: "max-width: 50px;",
          },
        },
        result: {
          type: "select",
          associatedOption: options.ldapResult,
          name: "Result",
          fieldAttrInput: { class: "required" },
          default: "error",
        },
        action: {
          type: "select",
          associatedOption: options.evalAction,
          name: "Action",
          default: "stopTest",
          fieldAttrInput: { class: "required" },
        },
        info: {
          type: "text",
          name: "Info",
          fieldAttrInput: { class: "" ,maxlength:'100' },
        },
        remove: lineButtons.remove,
      };
      return fields;
    },
    upLinkPairArray: {
      get() {
        let pairArray = [];
        let uplink = [];
        if (
          typeof this.stepStoredValue["res"]["eval"]["uplink"] != "undefined"
        ) {
          uplink = this.stepStoredValue["res"]["eval"]["uplink"];
        }
        uplink.forEach((value) => {
          pairArray.push({
            type: "uplink",
            ...value,
          });
        });
        return pairArray;
      },
    },
    downLinkPairArray: {
      get() {
        let pairArray = [];
        let downlink = [];
        if (
          typeof this.stepStoredValue["res"]["eval"]["downlink"] != "undefined"
        ) {
          downlink = this.stepStoredValue["res"]["eval"]["downlink"];
        }
        downlink.forEach((value) => {
          pairArray.push({
            type: "downlink",
            ...value,
          });
        });
        return pairArray;
      },
    },
    localStoredValue: {
      get() {
        // console.log(stepStoredValue);
        let stepStoredValue = this.stepStoredValue;
        if (typeof stepStoredValue.startWithinMinutes == "undefined") {
          stepStoredValue = JSON.parse(
            JSON.stringify(teststepDefault[this.stepType])
          );
          this.$emit("stored-value-changed", stepStoredValue);
        }
        if (typeof stepStoredValue.parameters.target.ip == "undefined") {
          this.$set(
            stepStoredValue.parameters.target,
            "ip",
            teststepDefault[this.stepType].parameters.target.ip
          );
          this.$emit("stored-value-changed", stepStoredValue);
        }
        return stepStoredValue;
      },
      set(newVal) {
        this.$emit("stored-value-changed", newVal);
      },
    },
    templateContent() {
      let templateContent = {
        fields: {
          timeLine: {
            type: "line",
            fields: {
              startWithinMinutes: {
                type: "number",
                name: "Start Within Minutes",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: { style: "max-width:170px", class: "" },
              },
              finishedWithinMinutes: {
                type: "number",
                name: "Finished Within Minutes",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: { style: "max-width:170px", class: "" },
              },
              maxDuration: {
                type: "number",
                name: "Maximum Duration (s)",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: { style: "max-width:170px", class: "" },
              },
            },
          },
          parameters: {
            inputFieldFormatter: "explorerUDP",
          },
        },
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        enableAdd: false,
        //contentID: this.stepType + "_" + this.stepID,
        contentID: this.stepType,
      };
      return templateContent;
    },
    onErrorVal: {
      get() {
        return this.stepStoredValue.res;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  methods: {
    changeTargetType(value) {
      this.$set(this.stepStoredValue.parameters.target, "type", value);
      this.$emit("stored-value-changed", this.stepStoredValue);
    },
    checkInput(newValueArray, type) {
      //console.log(newValueArray);
      let request = [];
      newValueArray.forEach((line) => {
        let insertedLine = {
          type: line.type,
          operator: line.operator,
          content: line.content,
          result: line.result,
          action: line.action,
          info: line.info,
        };
        if (typeof request != "undefined") {
          request.push(insertedLine);
        } else {
          request = [insertedLine];
        }
      });

      let localStoredStep = { ...this.stepStoredValue };
      if (typeof localStoredStep.res == "undefined") {
        this.$set(localStoredStep, "res", {});
      }
      if (typeof localStoredStep.res.eval == "undefined") {
        this.$set(localStoredStep.res, "eval", {});
      }
      this.$set(localStoredStep.res.eval, type, request);
      this.$emit("stored-value-changed", localStoredStep);
    },

    updateFieldValue(field, value) {
      //console.log("CORE", field, value);
      let localStoredStep = { ...this.stepStoredValue };
      if (field != "parameters" && field != "testDirection") {
        if (field == "downlink" || field == "uplink") {
          this.$set(localStoredStep["parameters"], field, value);
        } else {
          this.$set(localStoredStep, field, value);
        }
        this.$emit("stored-value-changed", localStoredStep);
      }
    },
  },
};
</script>